
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
import { Image as VanImage } from 'vant'
import 'amfe-flexible'
import { useRoute } from 'vue-router'

interface DataProps {
  taskList: any[]
  isVip: boolean
}
export default {
  name: '',
  components: { VanImage },
  setup() {
    console.log('1-开始创建组件-setup')
    const route = useRoute()

    const data: DataProps = reactive({
      isVip: false,
      userId: route.query.id,
      taskList: [
        {
          title: '实名认证',
          description: '完成实名认证',
          btnTitle: '去实名',
          icon: require('./assets/idCard.png'),
          current: 1,
          total: 1
        },
        {
          title: '邀请十人',
          description: '邀请十人亲友注册登录',
          btnTitle: '邀亲友',
          icon: require('./assets/invite.png'),
          current: 1,
          total: 10
        },
        {
          title: '认领树权',
          description: '认领一颗棋楠数权',
          btnTitle: '去认领',
          icon: require('./assets/tree.png'),
          current: 1,
          total: 1
        }
      ]
    })
    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount')
    })
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
    })
    return {
      tipsCircle: require('./assets/tips-circle.png'),
      noVipBackground: require('./assets/vipTop.png'),
      vipBackground: require('./assets/vipBackground.png'),
      ...toRefs(data)
    }
  }
}
